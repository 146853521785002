@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 800 !important;
  text-transform: capitalize !important;
}

h2, h3 {
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif;
}

h4, h5, h6 {
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

a:hover {
  text-decoration: none;
}

p {
  font-size: 1.1em !important;
}

.dashboard {
  background-color: #fff;
  border: 5px solid #fcfcfc;
  color: #333;
  width: 100% !important;
  border-radius: 10px;
  padding: 3vh;
  border: 5px solid #eee;
  margin-bottom: 2.5%;
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.01), 0 0px 20px rgba(0, 0, 0, 0.05);
}

.sidebar {
  background-color: #fff;
  border: 5px solid #fcfcfc;
  color: #333;
  width: 100% !important;
  border-radius: 10px;
  padding: 3vh;
  border: 5px solid #eee;
  margin-bottom: 2.5%;
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.01), 0 0px 20px rgba(0, 0, 0, 0.05);
}

.sidebar-left {
  border: 5px solid #eee;
  background-color: #fff;
  color: #000;
  width: 100%;
  border-radius: 10px;
  padding: 4vh;
  margin-bottom: 5%;
  text-align: left;
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.01), 0 0px 20px rgba(0, 0, 0, 0.05);
}


.masterdiv {
  width: 100%;
  min-height: 80vh;
  padding-top: 4vh !important;
  /* padding-bottom: 4vh !important; */
  font-family: 'PT Sans', sans-serif;
  min-height: 100vh
}

.centerX {
  align-items: center;
  justify-content: center;
  display: flex;
}

.center {
  text-align: center;
}

.overview {
  max-width: 80%;
  margin: 0 auto;
  align-self: center;
  padding: 2em;
  background-color: white;
  border-radius: 5px;
  clear: both;
  margin-bottom: 2vh;
}

.cta {
  min-width: 100%;
  background-color: #fcfcfc;
  font-family: 'PT Sans', sans-serif;
  padding: 4vh;
  padding-bottom: 4vh;
}

/* Simulator */

.admob {
  height: 55px;
  background-image: url('http://thetechnocafe.com/wp-content/uploads/2018/04/admob_banner.jpg');
  background-size: 100%;
}

.red {
  color: red
}

.mtb {
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.mtbs {
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
}

.mt {
  margin-top: 5vh;
}

.mts {
  margin-top: 1.5vh;
}

.mb {
  margin-bottom: 5vh;
}

.mbs {
  margin-bottom: 1.5vh;
}

.margin-r {
  margin-right: 5vh;
}

.margin-l {
  margin-left: 5vh;
}

.footer {
  color: white !important;
}

.wraptext {
  word-wrap: break-all !important;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}

.dropshadow {
  box-shadow: 14px 21px 42px 10px rgba(0, 0, 0, .2);
}

.lightshadow {
  box-shadow: 7px 5px 22px 5px rgba(0, 0, 0, .05);
}

.containerX {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 14px;
}

.cursor {
  cursor: pointer;
}

.blogpost-card {
  color: #000 !important;
}

.mouse-icon {
  width: 20px;
  height: 40px;
  border: 4px solid #000;
  border-radius: 20px;
  position: relative;
  animation: moveTop 1.5s infinite forwards;
  margin: 0 auto;
}

.mouse-icon:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 8px;
  background: blue;
  border-radius: 2px;
  top: 8px;
  left: 50%;
  transform: translatex(-50%);
}

.gradient {
  background: #000;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #fff, #fafafa);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #fff, #fafafa);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.white {
  color: '#fff' !important;
}

.screen-card {
  padding: 5avh !important;
  border-radius: 7px;
  box-shadow: 10px 5px 30px 6px rgba(0, 0, 0, .085)  !important;
}

@media screen and (min-width: 321px) {
  .footerGrid {
    padding: 0em !important;
  }
}

@media screen and (min-width: 801px) {
  .footerGrid {
    padding-left: 5em !important;
    padding-right: 5em !important;
    padding-bottom: 2.5em !important;
    padding-top: 2.5em !important;
  }
}

.row {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
